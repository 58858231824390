import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import axios from "axios";
import { CONTRACT_ENDPOINT } from "../endpoint";

const Inputcheck = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/complete");
  }
  const componentRef = useRef();
  const contactUs = useSelector(state => state.contactUs);
  const movingDate = useSelector(state => state.movingDate);
  const address = useSelector(state => state.address);
  const fee = useSelector(state => state.fee);
  const furniture = useSelector(state => state.furniture);
  const reconfirm = useSelector(state => state.reconfirm);
  const [furnitureNum, setFrunitureNum] = useState([]);
  const [buildingType, setBuildingType] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(CONTRACT_ENDPOINT, {
        data: {
          user_name: contactUs.name,
          user_hiragana: contactUs.furiName,
          phone_num: contactUs.phone,
          email: contactUs.email,
          current_address: address.currentPrefecture + address.currentCity,
          new_address: address.newPrefecture + address.newCity,
          selected_furnitures: furnitureNum,
          distance: reconfirm.ttlDistance,
          date: `${movingDate.year}-${movingDate.month}-${movingDate.date} ${movingDate.time}:00`,
          price: fee.totalFee,
          furnitures: furniture.furnitureData,
          building_type: furniture.building,
        }
      });
    } catch (error) {
      console.error('Error making request:', error);
    }
    routeChange();
  }
  useEffect(() => {
    const furnitureNumTemp = [];
    furniture.furnitureData.map((fData) => {
      const item = furniture.allFurnitures.find(allData => fData.furnitureId === allData.id);
      const temp = { item, fData };
      furnitureNumTemp.push(temp);
    });
    setFrunitureNum([...furnitureNumTemp]);
    setBuildingType(() => {
      switch (furniture.building) {
        case 1:
          return "";
        case 2:
          return "新築戸建";
        case 3:
          return "新築マンション";
        case 4:
          return "新築アパート";
        case 5:
          return "築浅物件";
        case 6:
          return "リフォーム物件";
        default:
          return;
      }
    })
  }, [])

  return (
    <section className="contact-us">
      <form onSubmit={handleSubmit}>
        <div className="contact" ref={componentRef}>
          <div className="bar"></div>
          <p>入力内容のご確認</p>
          <div className="state-display">
            <div className="customer-info state-btn">お客さま情報のご入力</div>
            <div className="input-confirm state-btn action">入力内容のご確認</div>
            <div className="application-complete state-btn">お申し込み完了</div>
          </div>
          <div className="other-confirm">
            <table>
              <tbody>
                <tr>
                  <td>お名前(漢字)</td>
                  <td>{contactUs.name}</td>
                </tr>
                <tr>
                  <td>フリガナ</td>
                  <td>{contactUs.furiName}</td>
                </tr>
                <tr>
                  <td>ご連絡先電話番号	</td>
                  <td>{contactUs.phone}</td>
                </tr>
                <tr>
                  <td>E-mailアドレス</td>
                  <td>{contactUs.email}</td>
                </tr>
                <tr>
                  <td>積み込み地の住所</td>
                  <td>{contactUs.currentAddress}</td>
                </tr>
                <tr>
                  <td>降し地（ご新居）の住所</td>
                  <td>{contactUs.newAddress}</td>
                </tr>
                <tr>
                  <td>選んだ家財、個数</td>
                  <td className="fur-style">
                    {furnitureNum.map((data, index) => (
                      <div className="furniture-align" key={index}>
                        <p className="align-left">{data.item.name} :</p><p className="align-right">{data.fData.furnitureNumber}個</p>
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>お引越し先の建物</td>
                  <td>{buildingType}</td>
                </tr>
                <tr>
                  <td>引越概算距離</td>
                  <td>
                    <p>{`${reconfirm.ttlDistance}km`}</p>
                    <p>※営業所拠点からの距離算出となります。</p>
                  </td>
                </tr>
                <tr>
                  <td>ご家財搬出予定日</td>
                  <td>{`${movingDate.year}年 ${movingDate.month}月 ${movingDate.date}日 ${movingDate.time < 12 ? "AM" : "PM"}`}</td>
                </tr>
                <tr>
                  <td>ご家財搬入予定日</td>
                  <td>仮申込み後、弊社オペレーターよりご連絡致します。</td>
                </tr>
                <tr>
                  <td>お引越合計料金（概算/税込）</td>
                  <td>{furniture.percentData2 < 100 ? `${fee.totalFee}円` : '訪問見積もり'}</td>
                </tr>
              </tbody>
            </table>
            <div className="abt-company">
              <p>株式会社ネクサスイノベーション </p>
              <p>全力引越し.com</p>
              <p>所在地 〒816-0901 福岡県大野城市乙金東2-18-8 オフィスパレア乙金Ⅱ-2号室</p>
            </div>
          </div>
        </div>
        <p className="line-contact">LINE登録でいつでもお手隙の際にご連絡。</p>
        <Link to={"https://page.line.me/282ybmsl?openQrModal=true"} className="line"><img src="./image/line.jpg" width={250} className="line-img" alt="LINE" /></Link>
        <ReactToPrint
          trigger={() => <a className="print-btn" href="#">お引越内容を印刷する</a>}
          content={() => componentRef.current}
        />
        <div className="input-btn">
          <Link to={"/contact"} className="input-previous-btn">入力画面へ戻る</Link>
          <button type="submit" className="input-next-btn">送信する</button >
        </div>
      </form>
    </section>
  )
}

export default Inputcheck